import React, { useEffect, useRef, useState } from 'react'
import Popover from '@mui/material/Popover'
import { Box } from '@mui/material'
import Typography from '../../ATOM/TEXT/Typography'
import PrimaryButton from '../../ATOM/BUTTON/PrimaryButton'
import FlexColWrap from '../../LAYOUT/WRAP/FlexColWrap'
import FlexWrap from '../../LAYOUT/WRAP/FlexWrap'
import CloseIcon from '@mui/icons-material/Close'

const PopOver = ({ title, btnText, children, onClick, onClose, width, minHeight, paperWidth }) => {
  // hook
  const btnRef = useRef()
  const [anchorEl, setAnchorEl] = useState(null)
  const [popoverStyle, setPopoverStyle] = useState({})

  const open = Boolean(anchorEl)

  // handler - pop over
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  // handler - close pop over
  const handleClose = () => {
    setAnchorEl(null)
  }

  const updatePopoverPosition = () => {
    if (btnRef?.current) {
      const btnRect = btnRef?.current?.getBoundingClientRect()
      // console.log(btnRect)
      setPopoverStyle({
        position: 'absolute',
        top: `${btnRect?.bottom + window.scrollY}px`,
        left: `${btnRect?.left + window.scrollX}px`,
      })
    }
  }

  useEffect(() => {
    updatePopoverPosition()

    window.addEventListener('resize', updatePopoverPosition)
    window.addEventListener('scroll', updatePopoverPosition)

    return () => {
      window.removeEventListener('resize', updatePopoverPosition)
      window.removeEventListener('scroll', updatePopoverPosition)
    }
  }, [btnRef])

  return (
    <>
      <PrimaryButton
        width={width || '156px'}
        minHeight={minHeight || '39px'}
        sx={{ paddingRight: '10px', paddingLeft: '10px' }}
        onClick={handleClick}
        ref={btnRef}
      >
        {btnText}
      </PrimaryButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={() => {
          if (onClose) onClose()
          handleClose()
        }}
        sx={{ ...popStyle, width: paperWidth || 'auto' }}
        disableScrollLock
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Box sx={{ p: '15px' }}>
          <FlexColWrap gap='15px'>
            <FlexWrap className='justify-between items-center'>
              {title && (
                <Typography
                  variant='h5'
                  className='font-bold'
                >
                  {title}
                </Typography>
              )}
              <PrimaryButton
                minWidth='55px'
                minHeight='30px'
                className='self-center text-[14px]'
                variant='contained'
                onClick={() => {
                  if (typeof onClick === 'function') onClick()
                  handleClose()
                }}
              >
                적용
              </PrimaryButton>
              {/* <CloseIcon
                  className='text-[16px] cursor-pointer'
                  onClick={handleClose}
                /> */}
            </FlexWrap>

            <div className='h-[1px] w-full bg-[var(--color-border-grey-400)]' />

            {children}
          </FlexColWrap>
        </Box>
      </Popover>
    </>
  )
}

export default PopOver

const popStyle = {
  '& .MuiPopover-paper': {
    // width: '180px',
    minWidth: '180px',
    position: 'absolute',
    overflow: 'visible',
    border: '1px solid var(--color-border-grey-500)',
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.25)',
  },
}
