import React from 'react'
import { bulkFields } from '../../REGIST/Components/TransExcelData'
import FlexWrap from '../../../../../../UI/LAYOUT/WRAP/FlexWrap'

const BulkEditTableHeader = ({ onSelectAll }) => {
  return (
    <thead>
      <tr>
        <th
          className='border border-solid bg-[#F2F4F6] border-[#D0D6DB] p-2 min-w-[50px]'
          rowSpan={2}
        >
          <input
            type='checkbox'
            onChange={onSelectAll}
          />
        </th>
        {bulkFields.map(({ label, required, colSpan, rowSpan }, index) => (
          <th
            key={index}
            className='border border-solid bg-[#F2F4F6] border-[#D0D6DB] p-2 min-w-[80px]'
            colSpan={colSpan}
            rowSpan={rowSpan}
          >
            <FlexWrap className='justify-center'>
              {label}
              {required && <span className='text-red-500'>*</span>}
            </FlexWrap>
          </th>
        ))}
      </tr>

      <tr>
        {/* 보호자1 세부 항목 */}
        <th className='border border-solid border-[#D0D6DB] bg-[#F2F4F6] p-2 min-w-[80px]'>이름</th>
        <th className='border border-solid border-[#D0D6DB] bg-[#F2F4F6] p-2 min-w-[80px]'>관계</th>
        <th className='border border-solid border-[#D0D6DB] bg-[#F2F4F6] p-2 min-w-[80px]'>전화번호</th>

        {/* 보호자2 세부 항목 */}
        <th className='border border-solid border-[#D0D6DB] bg-[#F2F4F6] p-2 min-w-[80px]'>이름</th>
        <th className='border border-solid border-[#D0D6DB] bg-[#F2F4F6] p-2 min-w-[80px]'>관계</th>
        <th className='border border-solid border-[#D0D6DB] bg-[#F2F4F6] p-2 min-w-[80px]'>전화번호</th>
      </tr>
    </thead>
  )
}

export default BulkEditTableHeader
