import React from 'react'
import { useRecoilValue } from 'recoil'
import dojangIdAtom from '../../../recoil/atom/dojangIdAtom'
import { useQuery } from '@tanstack/react-query'
import { Outlet } from 'react-router-dom'
import HealthCareIntro from '../../../UI/TEMPLATES/HEALTHCARE/HealthCareIntro/HealthCareIntro'
import { checkHealthCareState } from '../../../api/Healthcare/healthcareApi'
import { useDebug } from '../../../hooks/basic/useDebug'

const CheckJoinedHealthcare = () => {
  const dojangId = useRecoilValue(dojangIdAtom)

  const { data: healthCareState } = useQuery(['checkHealthCareState', dojangId], () => checkHealthCareState(dojangId), {
    enabled: !!dojangId,
    select: (res) => res,
  })

  // const healthCareState = false

  // return <Outlet />
  return !healthCareState ? <HealthCareIntro /> : <Outlet />
}

export default CheckJoinedHealthcare
