import React from 'react'
import { FormattedMessage } from 'react-intl'
import PrimaryButton from '../../../../../UI/ATOM/BUTTON/PrimaryButton'
import Typography from '../../../../../UI/ATOM/TEXT/HOME/Typography'

const SelectTypeButton = ({ onClick }) => {
  return (
    <PrimaryButton
      width='145px'
      height='38px'
      variant='contained'
      onClick={onClick}
      type='button'
    >
      <Typography className='font-bold text-[16px]'>
        <FormattedMessage id='다음' />
      </Typography>
    </PrimaryButton>
  )
}

export default SelectTypeButton
