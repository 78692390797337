import React, { useState } from 'react'
import FlexWrap from '../../../../../UI/LAYOUT/WRAP/FlexWrap'
import { FormattedMessage } from 'react-intl'
import Typography from '../../../../../UI/ATOM/TEXT/HOME/Typography'
import FlexColWrap from '../../../../../UI/LAYOUT/WRAP/FlexColWrap'
import SelectTypeButton from './SelectTypeButton'

const SelectTypePg = ({ onNext }) => {
  const [selectedOption, setSelectedOption] = useState('agent') // agent, direct

  // 옵션을 클릭했을 때 상태를 업데이트하는 함수
  const handleSelectOption = (option) => {
    setSelectedOption(option)
  }

  return (
    <FlexColWrap className='justify-center items-center'>
      <FlexWrap className='pt-[120px]'>
        <Typography className='text-[28px] '>
          <FormattedMessage id='PG가입 방법을 선택해주세요.' />
        </Typography>
      </FlexWrap>
      <FlexWrap className='pt-[50px] gap-[20px]'>
        {/* 태권월드 대행 서비스 */}
        <div
          onClick={() => handleSelectOption('agent')}
          className={`bg-white w-[429px] h-[284px] px-[25px] py-[30px] rounded shadow-lg text-[#000000] cursor-pointer ${
            selectedOption === 'agent' ? 'border-solid border-[#1890FF] ' : 'border-gray-300 border'
          }`}
        >
          <Typography className='text-[21px] pb-[30px]'>
            <FormattedMessage id='태권월드 대행 서비스 (권장)' />
          </Typography>
          <Typography className='text-[16px] leading-[30px]'>
            <FormattedMessage id='태권월드에서 PG가입 계약을 대행해드리고 있습니다.' />
            <br />
            <FormattedMessage id='필요 서류만 첨부해주시면, 대리 계약이 진행됩니다.' />
          </Typography>
          <Typography className='text-[16px] pt-[35px]'>
            <FormattedMessage id='※ cs팀에서 계좌인증을 위해 한 번 연락을 드립니다.' />
          </Typography>
        </div>

        {/* 직접 계약 */}
        <div
          onClick={() => handleSelectOption('direct')}
          className={`bg-white w-[429px] h-[284px] px-[25px] py-[30px] rounded shadow-lg text-[#000000] cursor-pointer ${
            selectedOption === 'direct' ? 'border-solid border-[#1890FF]' : 'border-gray-300 border'
          }`}
        >
          <Typography className='text-[21px] pb-[30px]'>
            <FormattedMessage id='직접 계약' />
          </Typography>
          <Typography className='text-[16px] leading-[30px]'>
            <FormattedMessage id='관장님께서 직접 계약을 진행합니다.' />
            <br />
            <FormattedMessage id='작성하신 이메일로 전자계약 링크가 발송이 되며,' />
            <br />
            <FormattedMessage id='전자계약시 필요서류제출, 약관동의 및 서명, PG설정,' />
            <br />
            <FormattedMessage id='계좌인증 등을 직접 진행하시게 됩니다.' />
          </Typography>
        </div>
      </FlexWrap>
      <FlexWrap className='pt-[60px]'>
        <SelectTypeButton onClick={() => onNext(selectedOption)} />
      </FlexWrap>
    </FlexColWrap>
  )
}

export default SelectTypePg
