import React, { useMemo } from 'react'
import { useRecoilValue } from 'recoil'
import { assoEngNameSelector, assoFullNameSelector } from '../../../../recoil/atom/association/assoInfoAtom'
import SideBarMenu from './Components/SideBarMenu'
import Typography from '../../../ATOM/TEXT/Typography'

const SideBarAssociation = () => {
  const s3URL = process.env.REACT_APP_AWS_S3_FILE_URL

  const redirectLogout = encodeURIComponent('account/login/association')

  const menu = useMemo(() => {
    return [
      {
        name: 'associtation-home',
        title: '홈',
        path: '/association/home',
        src: `${s3URL}/asset/images/icon/side_menu/home.png`,
      },
      {
        name: 'associtation-levelup',
        title: '승급 현황',
        path: '/association/levelup',
        src: `${s3URL}/asset/images/icon/side_menu/levelup.png`,
      },
      // {
      //   name: 'associtation-dojang',
      //   title: '도장 현황',
      //   path: '/association/dojang/list',
      //   src: `${s3URL}/asset/images/icon/side_menu/dojang.png`,
      // },
      // {
      //   name: 'associtation-student',
      //   title: '수련생 현황',
      //   path: '/',
      //   src: `${s3URL}/asset/images/icon/side_menu/student.png`,
      // },
      // {
      //   name: 'associtation-notice',
      //   title: '협회 공지',
      //   path: '/',
      //   src: `${s3URL}/asset/images/icon/side_menu/notice.png`,
      // },
      // {
      //   name: 'associtation-schedule',
      //   title: '협회 일정',
      //   path: '/',
      //   src: `${s3URL}/asset/images/icon/side_menu/attendance.png`,
      // },
    ]
  }, [])

  const otherMenu = useMemo(
    () => [
      {
        title: '로그아웃',
        path: '/logout',
        params: { url: redirectLogout },
        iconUrl: `${s3URL}/asset/images/icon/side_menu/logout.png`,
      },
    ],
    [],
  )

  const fullName = useRecoilValue(assoFullNameSelector)
  const engName = useRecoilValue(assoEngNameSelector)

  const image = {
    logo: `${s3URL}/asset/images/logo/TKW_LOGO.png`,
  }

  // useDebug(assoInfo)

  return (
    <div className='side-container'>
      <div className='fixed z-[9999]'>
        <div className='side-profile'>
          <a
            href='/'
            className='flex justify-center'
          >
            <img
              src={image.logo}
              alt='TAEKWON WORLD'
              className='w-full h-[50px] py-2 pr-2 pl-1 object-contain'
            />
          </a>
        </div>
        <div className='min-h-[118px] px-2 py-3 border-[1px] border-solid border-[var(--color-border-grey-400)] border-l-0 bg-[#fff]'>
          <div className='flex flex-col items-center gap-2'>
            <img
              src={`${s3URL}/asset/images/logo/association/${engName}_LOGO.png`}
              // src={`${s3URL}/asset/images/logo/association/${assoInfo?.eng_name}_LOGO.png`}
              alt=''
              className='w-[100px] h-[60px] object-contain drop-shadow'
            />
            <Typography className='w-[180px] text-center truncate font-bold'>{fullName}</Typography>
            {/* <p className='mb-0 font-bold text-[18px] break-keep'>{assoInfo?.full_name}</p> */}
          </div>
        </div>
      </div>
      {/* <div className='mt-[20px]'> */}
      <SideBarMenu
        menu={menu}
        otherMenu={otherMenu}
      />
      {/* </div> */}
    </div>
  )
}

export default SideBarAssociation
