import React from 'react'
import Typography from '../../../ATOM/TEXT/HOME/Typography'

const boxStyle = {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#1E3740',
  borderRadius: '40px',
}

const ColorBox = ({ py, className, children }) => {

  return (
    <div style={boxStyle} className={`${py ? `py-[${py}]` : 'py-[20px]'} px-[5px] ${className ?? ''}`}>
      {children}
    </div>
  )
}

export default ColorBox