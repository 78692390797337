import { atom, selector } from 'recoil'
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist({
  key: 'assoInfo',
  storage: sessionStorage,
})

const assoInfoAtom = atom({
  key: 'assoInfo',
  default: '',
  effects_UNSTABLE: [persistAtom],
})

export const assoFullNameSelector = selector({
  key: 'assoFullNameSelector',
  get: ({ get }) => {
    const assoInfo = get(assoInfoAtom)
    return assoInfo?.FULL_NAME
  },
})

export const assoEngNameSelector = selector({
  key: 'assoEngNameSelector',
  get: ({ get }) => {
    const assoInfo = get(assoInfoAtom)
    return assoInfo?.EN_NAME
  },
})

export const assoProvinceSelector = selector({
  key: 'assoProvinceSelector',
  get: ({ get }) => {
    const assoInfo = get(assoInfoAtom)
    return assoInfo?.PROVINCE
  },
})

export default assoInfoAtom
