import authApi from '../authApi'

// 헬스케어 사용여부
export const checkHealthCareState = async (dojang_id) => {
  const res = await authApi.get(`/v2/dojang/hc/using/${dojang_id}`)
  return res?.data
}

// 헬스케어 결과조회 리스트
export const getHealthcareResult = async (dojang_id, start, end) => {
  const res = await authApi.get(`/v2/dojang/hc/select-body/${dojang_id}?start=${start}&end=${end}`)
  return res?.data
}
