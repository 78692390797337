import React from 'react'
import { FormattedMessage } from 'react-intl'
import FlexWrap from '../../../../../../UI/LAYOUT/WRAP/FlexWrap'
import ArrowRightOutlinedIcon from '@mui/icons-material/ArrowRightOutlined'
import BulkButtonList from './BulkButtonList'

const BulkBtn = ({ label, withArrow = false, onClick }) => {
  return (
    <button
      className='flex justify-center items-center min-w-[156px] min-h-[38px] text-[16px] rounded-[2px] bg-white border border-solid border-[#000000] cursor-pointer transition duration-200 ease-in-out hover:bg-gray-100 active:bg-gray-200' // hover 및 active 상태 추가
      onClick={onClick}
    >
      <FormattedMessage id={label} />
      {withArrow && <ArrowRightOutlinedIcon />}
    </button>
  )
}

const BulkEditHeader = ({
  data,
  onUpdateGrade,
  onPromoteStudents,
  onDecreaseLevel,
  selectedStudents,
  setUpdatedData,
}) => {
  const noIconsMenus = ['일괄 승급', '일괄 감급', '학년 일괄증가(+)', '학년 일괄감소(-)']

  return (
    <FlexWrap className='gap-[10px]'>
      <BulkButtonList
        data={data}
        selectedStudents={selectedStudents}
        setUpdatedData={setUpdatedData}
      />
      {noIconsMenus.map((label) => (
        <BulkBtn
          key={label}
          label={label}
          onClick={
            label === '학년 일괄증가(+)'
              ? () => onUpdateGrade('increase')
              : label === '학년 일괄감소(-)'
              ? () => onUpdateGrade('decrease')
              : label === '일괄 승급'
              ? () => onPromoteStudents()
              : label === '일괄 감급'
              ? () => onDecreaseLevel()
              : null // 감급 처리 추가
          } // 클릭 핸들러 연결
        />
      ))}
    </FlexWrap>
  )
}

export default BulkEditHeader
