import React from 'react'
import BulkEditTableHeader from './BulkEditTableHeader'

const BulkEditTable = ({ data, selectedStudents, setSelectedStudents }) => {
  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedStudents(data.map((_, index) => index)) // 모든 학생의 index 선택
    } else {
      setSelectedStudents([]) // 전체 선택 해제
    }
  }

  const handleSelectStudent = (index) => {
    setSelectedStudents((prev) =>
      prev.includes(index) ? prev.filter((studentIndex) => studentIndex !== index) : [...prev, index],
    )
  }

  const renderCell = (content) => (
    <td className='border border-solid border-[#D0D6DB] p-[8px] text-[13px] min-w-[80px] h-[40px] overflow-hidden text-ellipsis whitespace-nowrap'>
      {content}
    </td>
  )

  const renderConvertCode = (code) => {
    if (code === '1') return '여자'
    if (code === '0') return '남자'
    return 'N/A'
  }

  const renderSchoolGrade = (grade) => {
    switch (parseInt(grade)) {
      case 1:
        return '초1'
      case 2:
        return '초2'
      case 3:
        return '초3'
      case 4:
        return '초4'
      case 5:
        return '초5'
      case 6:
        return '초6'
      case 7:
        return '중1'
      case 8:
        return '중2'
      case 9:
        return '중3'
      case 10:
        return '고1'
      case 11:
        return '고2'
      case 12:
        return '고3'
      case 13:
        return '성인'
      default:
        return '' // 빈 문자열 반환
    }
  }

  return (
    <>
      <BulkEditTableHeader onSelectAll={handleSelectAll} />
      <tbody>
        {data?.map((student, index) => (
          <tr key={index}>
            <td className='text-center border border-solid border-[#D0D6DB] p-2 min-w-[50px]'>
              <input
                type='checkbox'
                checked={selectedStudents.includes(index)}
                onChange={(e) => {
                  e.stopPropagation()
                  handleSelectStudent(index)
                }}
              />
            </td>
            {renderCell(student.TITLE)}
            {renderCell(student.LAST_NAME)}
            {renderCell(student.FIRST_NAME)}
            {renderCell(student.ATTEND_NUM)}
            {renderCell(renderConvertCode(student.SEX_CODE))}
            {renderCell(student.DOB)}
            {renderCell(student.STUDENT_LEVEL)}
            {renderCell(student.POOMDAN_GUEB)}
            {renderCell(student.PHONE_NUMBER)}
            {renderCell(student.REP_NAME)}
            {renderCell(student.REP_REL)}
            {renderCell(student.REP_PHONE_NUMBER)}
            {renderCell(student.REP_NAME_2)}
            {renderCell(student.REP_REL_2)}
            {renderCell(student.REP_PHONE_NUMBER_2)}
            {renderCell(student.PAYING_AMOUNT)}
            {renderCell(student.DUE_DATE)}
            {renderCell(student.ROAD_ADDRESS)}
            {renderCell(student.ADDRESS_DETAIL)}
            {renderCell(student.EMAIL)}
            {renderCell(student.SCHOOL)}
            {renderCell(renderSchoolGrade(student.SCHOOL_GRADE))}
            {renderCell(student.CAREER)}
            {renderCell(student.POOMDAN_CERTIFICATE_NUMBER)}
            {renderCell(student.POOMDAN_CERTIFICATE_DATE)}
            {renderCell(student.DOJANG_REG_DATE)}
            {renderCell(student.JUMP_LEVEL)}
            {renderCell(student.JUMP_CAREER)}
            {renderCell(student.HEIGHT)}
            {renderCell(student.WEIGHT)}
          </tr>
        ))}
      </tbody>
    </>
  )
}

export default BulkEditTable
