import authApi from '../authApi'

// PG 가입여부 조회
export const checkPGState = async (dojang_id) => {
  const res = await authApi.get(`/v2/dojang/pg-request/select/${dojang_id}`)
  return res.data
}

// PG 도장 정보 조회
export const checkDojangInfo = async (dojang_id) => {
  const res = await authApi.get(`/v2/dojang/pg-request/dojang-info/select/${dojang_id}`)
  return res.data
}

// PG 가입요청
export const createPGState = async (dojang_id, formData) => {
  const res = await authApi.post(`/v2/dojang/pg-request/create/${dojang_id}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
  return res.data
}

// 청구서 조회
export const getBillCheck = async (bill_id) => {
  const res = await authApi.get(`/v2/payment/bill/read/${bill_id}`)
  return res.data
}

// 토큰, 결제 데이터 조회
export const getBillTokenCheck = async (bill_id) => {
  const res = await authApi.get(`/v2/payment/start-pay/${bill_id}`)
  return res.data
}

// 결제 승인
export const successPayment = async (paymentData) => {
  const res = await authApi.post(`/v2/payment/check-pay`, paymentData)
  return res.data
}

// 결제 실패
export const failPayment = async (paymentData) => {
  const res = await authApi.post(`/v2/payment/save-fail`, paymentData)
  return res.data
}

// 청구항목 조회
export const getBillingItems = async (dojang_id) => {
  const res = await authApi.get(`/v2/dojang/product-name/select/${dojang_id}`)
  return res.data
}

// 청구항목 수정
export const updateBillingItems = async ([dojang_id, body]) => {
  const res = await authApi.put(`/v2/dojang/product-name/update/${dojang_id}`, body)
  return res.data
}
