import React from 'react'
import Typography from '../../../ATOM/TEXT/HOME/Typography'
import { useResize } from '../../../../hooks/window/useResize'

const SecondaryLineTitle = ({ px, pb, mb, className, children }) => {
  const title = useResize({
    lg: { variant: 'h2', leading: '60px' },
    md: { variant: 'h3', leading: '50px' },
    sm: { variant: 'h5', leading: '26px', bold: 'bold' },
  })

  const textStyle = useResize({
    sm: 'text-center',
  })

  return (
    <div
      className={`${px ? `px-[${px}]` : 'px-[10px]'} ${pb ? `pb-[${pb}]` : 'pb-[10px]'} ${
        mb ? `mb-[${mb}]` : 'mb-[10px]'
      } border border-solid border-[#777777] border-x-0 border-t-0 `}
    >
      <Typography
        {...title}
        className={`${textStyle} ${className ?? ''}`}
      >
        {children}
      </Typography>
    </div>
  )
}

export default SecondaryLineTitle
