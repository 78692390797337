import moment from 'moment'

export const bulkFields = [
  { label: '반', rowSpan: 2 },
  { label: '성', required: true, rowSpan: 2 }, // row[1]
  { label: '이름', required: true, rowSpan: 2 }, // row[2]
  { label: '출석번호', required: true, rowSpan: 2 }, // row[3]
  {
    label: '성별',
    required: true,
    type: 'select',
    rowSpan: 2,
    options: [
      { value: '', disabled: true },
      { value: '남', label: '남' },
      { value: '여', label: '여' },
    ],
  }, // 셀렉트 박스 필드 row[4]
  { label: '생년월일', required: true, rowSpan: 2 }, // row[5]
  {
    label: '급/품/단',
    type: 'select',
    rowSpan: 2,
    options: [
      { value: '', disabled: true },
      { value: '급', label: '급' },
      { value: '품', label: '품' },
      { value: '단', label: '단' },
    ], // 빈 값을 추가하여 기본값이 빈 값이 되도록 설정
  }, // row[7]
  { label: '품/단-급', rowSpan: 2 }, // row[8]
  { label: '휴대전화', rowSpan: 2 }, // row[9]
  { label: '보호자1', colSpan: 3 }, // row[10], row[11], row[12]
  { label: '보호자2', colSpan: 3 }, // row[13], row[14], row[15]
  { label: '월수련비', rowSpan: 2 }, // row[16]
  { label: '납부일', rowSpan: 2 }, // row[17]
  { label: '주소', rowSpan: 2 }, // row[18]
  { label: '상세주소', rowSpan: 2 }, // row[19]
  { label: '이메일', rowSpan: 2 }, // row[20]
  { label: '학교', rowSpan: 2 }, // row[21]
  {
    label: '학년',
    type: 'select',
    rowSpan: 2,
    options: [
      { value: '', disabled: true },
      { value: '초1', label: '초1' },
      { value: '초2', label: '초2' },
      { value: '초3', label: '초3' },
      { value: '초4', label: '초4' },
      { value: '초5', label: '초5' },
      { value: '초6', label: '초6' },
      { value: '중1', label: '중1' },
      { value: '중2', label: '중2' },
      { value: '중3', label: '중3' },
      { value: '고1', label: '고1' },
      { value: '고2', label: '고2' },
      { value: '고3', label: '고3' },
    ],
  }, // row[22]
  { label: '태권도 경력', rowSpan: 2 }, // row[23]
  { label: '품/단증 번호', rowSpan: 2 }, // row[24]
  { label: '품/단증 취득일', rowSpan: 2 }, // row[25]
  { label: '도장등록일', rowSpan: 2 }, // row[26]
  { label: '줄넘기 급수', rowSpan: 2 },
  { label: '줄넘기 경력', rowSpan: 2 },
  { label: '키', rowSpan: 2 },
  { label: '몸무게', rowSpan: 2 },
]

export const studentFields = [
  { label: '', rowSpan: 2 }, // 엑셀 데이터 B칸부터
  { label: '성', required: true, rowSpan: 2 }, // row[1]
  { label: '이름', required: true, rowSpan: 2 }, // row[2]
  { label: '출석번호', required: true, rowSpan: 2 }, // row[3]
  {
    label: '성별',
    required: true,
    type: 'select',
    rowSpan: 2,
    options: [
      { value: '', disabled: true },
      { value: '남', label: '남' },
      { value: '여', label: '여' },
    ],
  }, // 셀렉트 박스 필드 row[4]
  { label: '생년월일', required: true, rowSpan: 2 }, // row[5]
  { label: '급/품/단', rowSpan: 2 }, // row[6]
  {
    label: '급/품/단',
    type: 'select',
    rowSpan: 2,
    options: [
      { value: '', disabled: true },
      { value: '급', label: '급' },
      { value: '품', label: '품' },
      { value: '단', label: '단' },
    ], // 빈 값을 추가하여 기본값이 빈 값이 되도록 설정
  }, // row[7]
  { label: '품/단-급', rowSpan: 2 }, // row[8]
  { label: '휴대전화', rowSpan: 2 }, // row[9]
  { label: '보호자1', colSpan: 3 }, // row[10], row[11], row[12]
  { label: '보호자2', colSpan: 3 }, // row[13], row[14], row[15]
  { label: '월수련비', rowSpan: 2 }, // row[16]
  { label: '납부일', rowSpan: 2 }, // row[17]
  { label: '주소', rowSpan: 2 }, // row[18]
  { label: '상세주소', rowSpan: 2 }, // row[19]
  { label: '이메일', rowSpan: 2 }, // row[20]
  { label: '학교', rowSpan: 2 }, // row[21]
  {
    label: '학년',
    type: 'select',
    rowSpan: 2,
    options: [
      { value: '', disabled: true },
      { value: '초1', label: '초1' },
      { value: '초2', label: '초2' },
      { value: '초3', label: '초3' },
      { value: '초4', label: '초4' },
      { value: '초5', label: '초5' },
      { value: '초6', label: '초6' },
      { value: '중1', label: '중1' },
      { value: '중2', label: '중2' },
      { value: '중3', label: '중3' },
      { value: '고1', label: '고1' },
      { value: '고2', label: '고2' },
      { value: '고3', label: '고3' },
    ],
  }, // row[22]
  { label: '태권도 경력', rowSpan: 2 }, // row[23]
  { label: '품/단증 번호', rowSpan: 2 }, // row[24]
  { label: '품/단증 취득일', rowSpan: 2 }, // row[25]
  { label: '도장등록일', rowSpan: 2 }, // row[26]
  { label: '줄넘기 급수', rowSpan: 2 },
  { label: '줄넘기 경력', rowSpan: 2 },
  { label: '키', rowSpan: 2 },
  { label: '몸무게', rowSpan: 2 },
]

// 등록 시 validation 체크
export const requiredFieldsIndex = [
  { index: 1, name: '성' },
  { index: 2, name: '이름' },
  { index: 3, name: '출석번호' },
  { index: 4, name: '성별' },
  { index: 5, name: '생년월일' },
]

// 서버로 보내는 엑셀 데이터
export const transformExcelDataToPayload = (filterData) => {
  return filterData.map((row) => {
    const currentDate = moment().format('YYYYMMDD')

    return {
      LAST_NAME: String(row[1]), // 예시: '성' *필수
      FIRST_NAME: String(row[2]), // 예시: '이름' *필수
      ATTEND_NUM: String(row[3]), // 예시: '출석번호' *필수
      SEX_CODE: row[4] === '남' ? '2' : row[4] === '여' ? '1' : '',
      DOB: String(row[5]), // 생년월일 *필수
      STUDENT_LEVEL: String(row[6]) + String(row[7]), // 급/품/단
      POOMDAN_GUEB: Number(row[8]), // 품/단-급
      PHONE_NUMBER: String(row[9]), // 휴대전화
      REP_NAME: String(row[10]), // 보호자1 이름
      REP_REL: String(row[11]), // 보호자1 관계
      REP_PHONE_NUMBER: String(row[12]), // 보호자1 전화번호
      REP_NAME_2: String(row[13]), // 보호자2 이름
      REP_REL_2: String(row[14]), // 보호자2 관계
      REP_PHONE_NUMBER_2: String(row[15]), // 보호자2 전화번호
      PAYING_AMOUNT: Number(row[16]), // 월수련비
      DUE_DATE: Number(row[17]), // 납부일
      ROAD_ADDRESS: String(row[18]), // 주소
      ADDRESS_DETAIL: String(row[19]), // 상세주소
      EMAIL: String(row[20]), // 이메일
      SCHOOL: String(row[21]), // 학교
      SCHOOL_GRADE: Number(row[22]), // 학년
      CAREER: Number(row[23]), // 태권도 경력
      POOMDAN_CRETIFICATE_NUMBER: String(row[24]),
      POOMDAN_CRETIFICATE_DATE: String(row[25]),
      DOJANG_REG_DATE: row[26] ? String(row[26]) : currentDate, // 도장등록일
      JUMP_LEVEL: String(row[27]), // 줄넘기 급수
      JUMP_CAREER: String(row[28]), // 줄넘기 경력
      HEIGHT: parseFloat(row[29]), // 키
      WEIGHT: parseFloat(row[30]), // 몸무게
    }
  })
}

// 서버 에러 -> alert으로 바꾸는 함수
export const RegisterExcelError = (error) => {
  const errorMessage = error.response?.data?.message || ''
  const fieldError = error.response?.data.error || ''

  // 중복된 ATTEND_NUM 확인
  if (errorMessage.includes('ATTEND_NUM is already exist')) {
    // 출석번호 부분만 추출 (예: "0001,0002,0003,...")
    const duplicateNumbersMatch = errorMessage.match(/(\d{4}(?:,\d{4})*)/)

    if (duplicateNumbersMatch) {
      const duplicateNumbers = duplicateNumbersMatch[0] // 매칭된 번호들
      const formattedNumbers = duplicateNumbers.split(',').join(', ')

      alert(`중복된 출석번호가 있습니다: ${formattedNumbers}`)
    }
    return
  }

  // 에러 타입에 따라 맵핑
  const errorMessagesMap = {
    LAST_NAME: '성을 입력하지 않았습니다.',
    FIRST_NAME: '이름을 입력하지 않았습니다.',
    ATTEND_NUM: '출석번호를 입력하지 않았습니다.',
    SEX_CODE: '성별을 선택하지 않았습니다.',
    DOB: '생년월일을 입력하지 않았습니다.',
  }

  // 에러 메시지에서 빈 필드와 행 번호를 추출 (예: "[7].LAST_NAME")
  const matchedError = fieldError.match(/\[(\d+)\]\.(\w+)/)

  if (matchedError) {
    const rowNumber = parseInt(matchedError[1], 10)
    const field = matchedError[2]
    const userFriendlyMessage = errorMessagesMap[field] || '필수 항목을 입력하지 않았습니다.'

    // 추출한 행 번호에 +1
    alert(`행 ${rowNumber + 1}번 째에서 오류 발생: ${userFriendlyMessage}`)
  } else {
    alert('수련생 등록 중 오류가 발생했습니다.')
  }
}
