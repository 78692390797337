import React, { createContext } from 'react'
import Select from '../../ATOM/SELECT/Select'
import { FormattedMessage, useIntl } from 'react-intl'
import { useRecoilValue } from 'recoil'
import dojangIdAtom from '../../../recoil/atom/dojangIdAtom'
import { useQuery } from '@tanstack/react-query'
import { getClassList } from '../../../api/Class/ClassApi'
import { toLowerArray } from '../../../UTILS/render/toLower'
import { MenuItem } from '@mui/material'
import ApiErrorBoundarySelectBox from '../../TEMPLATES/ERROR/ErrorBoundary/ApiErrorBoundarySelectBox'

const ClassSelect = React.forwardRef(
  ({ allTitle, only, onChange, filteredClassId, includingNoClass, ...props }, ref) => {
    return (
      <ApiErrorBoundarySelectBox>
        <ClassSelectFetcher>
          <ClassSelectContext.Consumer>
            {({ data }) => (
              <Select
                item={!filteredClassId ? data : data?.filter((item) => item.id !== filteredClassId)}
                value={filteredClassId || 0}
                onChange={(e) => setTimeout(() => onChange(e), 50)}
                {...props}
              >
                {!only && (
                  <MenuItem value={0}>
                    <FormattedMessage id={allTitle ?? '전체반'} />
                  </MenuItem>
                )}
                {includingNoClass && (
                  <MenuItem value={0}>
                    {'-'}
                    {/* <FormattedMessage id={'반 없음'} /> */}
                  </MenuItem>
                )}
              </Select>
            )}
          </ClassSelectContext.Consumer>
        </ClassSelectFetcher>
      </ApiErrorBoundarySelectBox>
    )
  },
)

ClassSelect.displayName = 'ClassSelect'
export default ClassSelect

const ClassSelectContext = createContext()

const ClassSelectFetcher = ({ children }) => {
  const dojangId = useRecoilValue(dojangIdAtom)

  const body = {
    dojang_id: dojangId,
  }

  // query
  const { data } = useQuery(['getClassList', body], () => getClassList(body), {
    enabled: !!body,
    select: (res) => {
      let temp = toLowerArray(res)

      // class list
      const classList = temp.map((item) => ({
        id: item?.class_id,
        title: item?.title,
      }))

      // sort by title
      const sortedList = classList?.sort((a, b) => {
        if (a.title < b.title) return -1
      })
      // console.log('Debugging data:', sortedList)
      return sortedList
    },
  })

  return <ClassSelectContext.Provider value={{ data }}>{children}</ClassSelectContext.Provider>
}
