import React, { useContext, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import FlexColWrap from '../../../../../../UI/LAYOUT/WRAP/FlexColWrap'
import FlexWrap from '../../../../../../UI/LAYOUT/WRAP/FlexWrap'
import InputControlled from '../../../../../../UI/MOLECULES/INPUT/Controlled/InputControlled'
import PopOver from '../../../../../../UI/MOLECULES/POPOVER/PopOver'
// import ClassTitleSelect from './ClassTitleSelect'
import ClassSelect from '../../../../../../UI/MOLECULES/SELECT/ClassSelect'

const BulkButtonList = ({ selectedStudents, setUpdatedData, data }) => {
  const defaultVal = { TITLE: '', STUDENT_LEVEL: '', CLASS_ID: 0 }
  const { control, setValue, getValues, watch, reset } = useForm({ defaultValues: defaultVal })
  const selectedClassId = watch('CLASS_ID') || 0 // TITLE 값으로 watch

  // console.log(data, '현재 데이터 배열')

  const handleClassChange = (selectedClassId) => {
    if (selectedStudents.length === 0) {
      alert('일괄적용할 셀을 선택해주세요')
      return
    }

    if (!data || data.length === 0) {
      console.error('데이터가 아직 로드되지 않았습니다.')
      return
    }

    // console.log('selectedClassId:', selectedClassId, '타입:', typeof selectedClassId)

    const newTitle = data.find((item) => Number(item.CLASS_ID) === Number(selectedClassId))?.TITLE

    // console.log('찾은 newTitle:', newTitle)

    // 선택된 학생들에게 반 정보 일괄 적용
    setUpdatedData((prevData) => {
      const updatedStudents = prevData.map((student, index) => {
        if (selectedStudents.includes(index)) {
          return { ...student, CLASS_ID: selectedClassId, TITLE: newTitle }
        }
        return student
      })
      return updatedStudents
    })

    // 선택된 값으로 업데이트
    // setValue('TITLE', newTitle)
  }

  // 급품단 일괄적용 함수
  const handleLevelApply = () => {
    if (selectedStudents.length === 0) {
      alert('일괄적용할 셀을 선택해주세요') // 선택된 셀이 없을 때 경고 메시지 표시
      return
    }

    const newLevel = getValues('STUDENT_LEVEL') // 사용자가 입력한 급품단 값 가져오기

    // 선택된 학생들에게 새로운 급품단 일괄 적용
    setUpdatedData((prevData) => {
      return prevData.map((student, index) => {
        if (selectedStudents.includes(index)) {
          return { ...student, STUDENT_LEVEL: newLevel } // 새로운 급품단으로 업데이트
        }
        return student
      })
    })
  }

  // 월 수련비 일괄적용 함수
  const handlePayingAmount = () => {
    if (selectedStudents.length === 0) {
      alert('일괄적용할 셀을 선택해주세요')
      return
    }

    const newPay = getValues('PAYING_AMOUNT') // 사용자가 입력한 수련비 값 가져오기

    // 선택된 학생들에게 새로운 급품단 일괄 적용
    setUpdatedData((prevData) => {
      return prevData.map((student, index) => {
        if (selectedStudents.includes(index)) {
          return { ...student, PAYING_AMOUNT: newPay } // 새로운 수련비로 업데이트
        }
        return student
      })
    })
  }

  // 월 수련비 일괄적용 함수
  const handleDueDate = () => {
    if (selectedStudents.length === 0) {
      alert('일괄적용할 셀을 선택해주세요')
      return
    }

    const newDate = getValues('DUE_DATE') // 사용자가 입력한 수련비 값 가져오기

    // 선택된 학생들에게 새로운 급품단 일괄 적용
    setUpdatedData((prevData) => {
      return prevData.map((student, index) => {
        if (selectedStudents.includes(index)) {
          return { ...student, DUE_DATE: newDate } // 새로운 수련비로 업데이트
        }
        return student
      })
    })
  }

  const btnList = useMemo(
    () => [
      {
        title: '반 일괄적용',
        btnText: '반 일괄적용',
        children: (
          <FlexColWrap gap='8px'>
            <Controller
              name='TITLE'
              control={control}
              render={({ field }) => (
                <ClassSelect
                  value={selectedClassId}
                  filteredClassId={selectedClassId}
                  onChange={(e) => {
                    console.log(e.target.value, 'onChange로 전달된 값')
                    handleClassChange(e.target.value)
                  }}
                />
                // <ClassTitleSelect
                //   data={data}
                //   selectedClassId={selectedClassId}
                //   onChange={handleClassChange}
                // />
              )}
            />
          </FlexColWrap>
        ),
      },
      {
        title: '급품단 일괄적용',
        btnText: '급품단 일괄적용',
        children: (
          <InputControlled
            width='150px'
            name='STUDENT_LEVEL'
            control={control}
            adornment='급/품/단'
            fontSize='14px'
          />
        ),
        onClick: handleLevelApply,
      },
      {
        title: '월 수련비 일괄적용',
        btnText: '월 수련비 일괄적용',
        children: (
          <InputControlled
            width='150px'
            name='PAYING_AMOUNT'
            control={control}
            adornment='원'
            fontSize='14px'
          />
        ),
        onClick: handlePayingAmount,
      },
      {
        title: '납부일 일괄적용',
        btnText: '납부일 일괄적용',
        children: (
          <InputControlled
            width='150px'
            name='DUE_DATE'
            control={control}
            adornment='일'
            fontSize='14px'
          />
        ),
        onClick: handleDueDate,
      },
    ],
    [control, selectedClassId, setUpdatedData, selectedStudents],
  )

  return (
    <FlexWrap className='gap-[7px]'>
      {btnList.map((item, index) => (
        <PopOver
          key={index}
          title={item.title}
          btnText={item.btnText}
          onClick={item.onClick}
          onClose={() => reset(defaultVal, { keepDefaultValues: true })}
        >
          {item.children}
        </PopOver>
      ))}
    </FlexWrap>
  )
}

export default BulkButtonList
