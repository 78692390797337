import { s3URLToFile } from '../../UTILS/AWS/FILE/s3URLToFile'
import authApi from '../authApi'
import { FormDataConfig } from '../config'

// 도장, 반 별 원생 조회 (수정)
export const getStudent = async (body) => {
  // const fullBody = addCountryCode(body);
  const res = await authApi.post('student/studentreadbyclass', body)
  return res.data
}

// 수련생 보호자 정보 (수정 get -> post)
export const getStudentRelativeInfo = async (body) => {
  const res = await authApi.post('student/studentrelativeread', body)
  return res.data
}

// 원생 조회
export const getStudentInfo = async (body) => {
  const { data } = await authApi.post('student/studentread', body)

  if (!data?.student_info?.[0]?.PROFILE_IMG) return data

  const profilePromise = await s3URLToFile(data?.student_info?.[0]?.PROFILE_IMG, 'profile_img')
  const [profile] = await Promise.all([profilePromise])

  return {
    ...data,
    student_info: [
      {
        ...data?.student_info?.[0],
        previewUrl: URL.createObjectURL(profile),
        img: profile,
      },
    ],
  }
}

// 원생 조회[manage/Student-3]
// export const getStudentInfo = async (studentId) => {
//   const res = await authApi.get(`student/studentread/${studentId}`)
//   return res.data[0]
// }

// 원생 수정(수정)
// export const updateStudentInfo = async (body) => {
//   const res = await authApi.post('student/studentupdate', body, FormDataConfig)
//   return res.data
// }

// // 원생 <-> 반 추가
// export const updateStudentClass = async (studentId, classId) => {
//   const res = await authApi.post(`student/studentclassupdate/${classId}/${studentId}`)
//   return res
// }

// 원생 삭제
// export const deleteStudent = async (studentId) => {
//   const res = await authApi.put(`student/studentupdate/${studentId}`)
//   return res
// }

// 원생 퇴소 (수정 delete -> post)
export const outStudent = async (body) => {
  const res = await authApi.post('student/studentdischarge', body)
  return res.data
}

// 신규 수련생 등록(수정)
export const createStudent = async (body) => {
  const res = await authApi.post('student/studentcreate', body, FormDataConfig)
  return res.data
}

// 기존 수련생 등록 - 정보 (수정 get -> post)
export const getExistStudentByInfo = async (body) => {
  const res = await authApi.post('student/studentreadbybasic', body)
  // const res = await authApi.get(`student/studentreadbybasic?student_lastname=${last_name}&student_firstname=${first_name}&student_dob=${dob}&student_sex=${sex}`)
  return res.data
}

// 기존 수련생 등록 - 코드 (수정 get -> post)
export const getExistStudentByCode = async (body) => {
  const res = await authApi.post('student/studentreadbyunique', body)
  return res.data
}

export const selectExistStudent = async ([studentId, body]) => {
  const res = await authApi.post(`student/studentupdate/${studentId}`, body)
  return res
}

// 기존 수련생 일괄 등록
export const createStudentBulk = async (body) => {
  const res = await authApi.post('kwanjang/st_bulk_create', body, FormDataConfig)
  return res.data
}

export const createStudentTKFriends = async (body) => {
  const res = await authApi.post('kwanjang/st_bulk_create_tf', body)
  return res.data
}

/////////////////////////////////////  VERSION 2  /////////////////////////////////////////
// 수련생 전체 조회 (휴관 포함)
export const getStudentList = async (dojang_id) => {
  const res = await authApi.get(`v2/student/all/read/${dojang_id}`)
  return res.data
}

// 수련생 조회 (휴관 제외)
export const getStudentListExceptRest = async (dojang_id) => {
  const res = await authApi.get(`v2/student/except-rest/read/${dojang_id}`)
  return res.data
}

// 수련생 상세 조회
export const getStudentDetail = async (dojang_id, student_id) => {
  const { data } = await authApi.get(`v2/student/detail/read/${dojang_id}/${student_id}`)
  if (!data?.PROFILE_IMG) return { ...data, PREVIEWURL: '' }

  const profilePromise = await s3URLToFile(data?.PROFILE_IMG, 'profile_img')
  const [profile] = await Promise.all([profilePromise])

  return {
    ...data,
    PREVIEWURL: URL.createObjectURL(profile),
    img: profile,
  }
}

// 수련생 정보 수정
export const updateStudentInfo = async ([dojang_id, student_id, body]) => {
  const res = await authApi.put(`/v2/student/detail/update/${dojang_id}/${student_id}`, body, FormDataConfig)
  return res.data
}

// 수련생 휴관 등록
export const registRestStudent = async ([dojang_id, body]) => {
  const res = await authApi.put(`v2/student/rest-state/update/${dojang_id}`, body)
  return res.data
}

// 휴관 수련생 조회
export const getRestStudentList = async (dojang_id) => {
  const res = await authApi.get(`v2/student/reststudent/read/${dojang_id}`)
  return res.data
}

// 휴관 수련생 복귀
export const reRegistRestStudent = async ([dojang_id, body]) => {
  const res = await authApi.put(`v2/student/rest-state/comeback/update/${dojang_id}`, body)
  return res.data
}

// 수련생 등록
export const registNewStudent = async ([dojang_id, invite_yn, body]) => {
  const res = await authApi.post(`v2/student/create/${dojang_id}/${invite_yn}`, body, FormDataConfig)
  return res.data
}

// 출석번호 중복 확인
export const checkDuplicateAttnum = async ([dojang_id, attend_number]) => {
  const res = await authApi.get(`v2/student/check-duplicate-attendnumber/${dojang_id}/${attend_number}`)
  return res.data
}

// 새로운 수련생 일괄 등록
export const createStudentForBulk = async (dojang_id, invite_yn, body) => {
  const res = await authApi.post(`/v2/student/create/for-bulk-student/${dojang_id}/${invite_yn}`, body)
  return res.data
}

// 일괄 수정 수련생 조회
export const getBulkListExcel = async (dojang_id) => {
  const res = await authApi.get(`/v2/student/all/read/bulk/${dojang_id}`)
  return res.data
}

// 수련생 일괄 수정
export const putBulkListExcel = async (dojang_id, updateData) => {
  const res = await authApi.put(`/v2/student/update/bulk/${dojang_id}`, updateData)
  return res.data
}
