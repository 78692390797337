import React from 'react'
import { FormattedMessage } from 'react-intl'
import PrimaryButton from '../../../../../UI/ATOM/BUTTON/PrimaryButton'
import Typography from '../../../../../UI/ATOM/TEXT/HOME/Typography'

const PgSignButton = ({ disabled }) => {
  return (
    <PrimaryButton
      width='340px'
      height='38px'
      variant='contained'
      type='submit'
      disabled={disabled}
      className={`${disabled ? 'bg-gray-300 cursor-not-allowed' : 'bg-blue-500 cursor-pointer'}`} // 상태에 따른 스타일 변화
    >
      <Typography className='font-bold text-[16px]'>
        <FormattedMessage id='PG 가입요청' />
      </Typography>
    </PrimaryButton>
  )
}

export default PgSignButton
