import React from 'react'

const ButtonWrap = ({ mt, className, children }) => {
  return (
    <div className={`${className ?? ''} flex gap-[10px] justify-center ${mt ? `mt-[${mt}]` : 'mt-[60px]'} `}>
      {children}
    </div>
  )
}

export default ButtonWrap
