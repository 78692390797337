import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import PrimaryButton from '../../../../../UI/ATOM/BUTTON/PrimaryButton'
import Container from '../../../../../UI/LAYOUT/CONTAINER/Container'
import ApiErrorBoundary from '../../../../../UI/TEMPLATES/ERROR/ErrorBoundary/ApiErrorBoundary'
import useNavSearch from '../../../../../hooks/navigation/useNavSearch'
import BulkEditComponents from './Components/BulkEditComponents'
import { useMutation } from '@tanstack/react-query'
import { putBulkListExcel } from '../../../../../api/Student/studentApi'
import { useRecoilValue } from 'recoil'
import dojangIdAtom from '../../../../../recoil/atom/dojangIdAtom'

const ManageStudentBulkEdit = () => {
  const nav = useNavSearch()
  const [updatedData, setUpdatedData] = useState([]) // updateData 상태 관리
  const dojang_id = useRecoilValue(dojangIdAtom)

  const { mutate: saveBulkEdit } = useMutation({
    mutationFn: (updatedData) => putBulkListExcel(dojang_id, updatedData),
    onSuccess: () => {
      alert('수정 사항이 성공적으로 저장되었습니다.')
      nav('/manage/student/list', { class: 0 }) // 성공 시 페이지 이동
    },
    onError: () => {
      alert('저장에 실패했습니다. 다시 시도해주세요.')
    },
  })

  const handleSave = () => {
    const transformedData = updatedData.map((student) => {
      const sexCode = student.SEX_CODE === '1' ? 1 : student.SEX_CODE === '0' ? 2 : 1
      return {
        ...student,
        SEX_CODE: sexCode,
      }
    })

    saveBulkEdit(transformedData)
  }

  return (
    <>
      <Container
        title={<FormattedMessage id='수련생 일괄수정' />}
        onClickArrow={() => nav('/manage/student/list', { class: 0 })}
        btn={
          <PrimaryButton
            width='114px'
            height='40px'
            variant='contained'
            type='submit'
            onClick={handleSave}
          >
            <FormattedMessage id='저장' />
          </PrimaryButton>
        }
      >
        <ApiErrorBoundary>
          <BulkEditComponents setUpdatedData={setUpdatedData} />
        </ApiErrorBoundary>
      </Container>
    </>
  )
}

export default ManageStudentBulkEdit
