import React, { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { FormattedMessage, useIntl } from 'react-intl'
import { checkDojangInfo, createPGState } from '../../../../../api/Payment/payment'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { IconButton } from '@mui/material'
import dojangIdAtom from '../../../../../recoil/atom/dojangIdAtom'
import FlexWrap from '../../../../../UI/LAYOUT/WRAP/FlexWrap'
import InputControlled from '../../../../../UI/MOLECULES/INPUT/Controlled/InputControlled'
import AlignLabel from '../../../../../UI/ATOM/LABEL/AlignLabel'
import PgSignButton from './PgSignButton'
import ModalAddress from './ModalAddress'
import PrimaryButton from '../../../../../UI/ATOM/BUTTON/PrimaryButton'
import ImageSearchIcon from '@mui/icons-material/ImageSearch'

const PgSignForm = ({ selectedOption }) => {
  const s3URL = process.env.REACT_APP_AWS_S3_FILE_URL
  const { control, handleSubmit, setValue, getValues, setError } = useForm()
  const dojang_id = useRecoilValue(dojangIdAtom)
  const navigate = useNavigate()

  // intl
  const intl = useIntl()
  const coNmMsg = intl.formatMessage({ id: '상호를 입력해주세요.' })
  const MinLenMsg = intl.formatMessage({ id: '최소 2자 이상 입력해주세요.' })
  const coNoMsg = intl.formatMessage({ id: '사업자 번호를 입력해주세요.' })
  const coRegMsg = intl.formatMessage({ id: '법인사업자일 경우 입력해주세요.' })
  const coReqMin = intl.formatMessage({ id: '법인등록번호는 13자리여야 합니다.' })
  const coReqMax = intl.formatMessage({ id: '법인등록번호는 13자리를 초과할 수 없습니다.' })
  const openMsg = intl.formatMessage({ id: '사업개시일을 8자리로 입력해주세요. ex)19990101' })
  const repNmMsg = intl.formatMessage({ id: '대표자명을 입력해주세요.' })
  const repBirthDtMsg = intl.formatMessage({ id: '생년월일은 8자리로 입력해주세요. ex)19990101' })
  const phoneMsg = intl.formatMessage({ id: '대표자 휴대폰 번호를 입력해주세요. 하이픈(-) 없이 입력. 예: 01012345678' })
  const phonePattern = intl.formatMessage({ id: '유효한 휴대폰 번호 형식을 입력해주세요. ex)01012345678' })
  const telNoMsg = intl.formatMessage({ id: '전화번호를 입력해주세요.' })
  const phoneMin = intl.formatMessage({ id: '휴대폰 번호는 최소 10자리여야 합니다.' })
  const phoneMax = intl.formatMessage({ id: '휴대폰 번호는 최대 11자리여야 합니다.' })
  const emailMsg = intl.formatMessage({ id: '이메일을 입력해주세요.' })
  const emailPattern = intl.formatMessage({ id: '유효한 이메일 형식을 입력해주세요.' })
  const postMsg = intl.formatMessage({ id: '우편번호를 입력해주세요.' })
  const postClickMsg = intl.formatMessage({ id: '우편번호를 입력하려면 클릭하세요.' })
  const addrMsg = intl.formatMessage({ id: '주소를 입력해주세요.' })
  const addrDetailMsg = intl.formatMessage({ id: '상세주소를 입력해주세요.' })
  const brMsg = intl.formatMessage({ id: '사업자등록증을 업로드 해주세요.' })
  const frontIdMsg = intl.formatMessage({ id: '신분증 앞면을 업로드 해주세요.' })
  const backIdMsg = intl.formatMessage({ id: '신분증 뒷면을 업로드 해주세요.' })
  const accountMsg = intl.formatMessage({ id: '통장사본을 업로드 해주세요.' })

  // hooks
  const [isModalOpen, setIsModalOpen] = useState(false) // 우편번호 검색 모달
  const [businessType, setBusinessType] = useState('personal') // 사업자선택 상태
  const [agree, setAgree] = useState(true) // 대리계약 동의 상태
  const [fileNames, setFileNames] = useState({ br_img: '', account_copy: '', front_id_card: '', back_id_card: '' }) // agent 시 업로드 파일 이름
  const [uploadedFiles, setUploadedFiles] = useState({
    br_img: null,
    account_copy: null,
    front_id_card: null,
    back_id_card: null,
  }) // agent 시 업로드 필요한 파일

  // 초기 유저값 get
  const { data } = useQuery(['dojangInfo', dojang_id], () => checkDojangInfo(dojang_id), { enabled: !!dojang_id })

  // 초기 유저값 세팅
  useEffect(() => {
    if (data) {
      const {
        DOJANG_NAME,
        BR_NUMBER,
        ROAD_ADDRESS,
        ADDRESS_DETAIL,
        LAST_NAME,
        FIRST_NAME,
        DOB,
        BR_IMG,
        REP_PHONE_NUMBER,
        EMAIL,
      } = data
      setValue('coNm', DOJANG_NAME)
      setValue('coNo', BR_NUMBER)
      setValue('addrNo1', ROAD_ADDRESS)
      setValue('addrNo2', ADDRESS_DETAIL)
      setValue('repNm', `${LAST_NAME}${FIRST_NAME}`)
      setValue('repBirthDt', DOB)
      setValue('br_img_name', BR_IMG ? `${s3URL}/${BR_IMG}` : '')
      setValue('repHp', REP_PHONE_NUMBER)
      setValue('telNo', REP_PHONE_NUMBER)
      setValue('email', EMAIL)
    }
  }, [data, setValue])

  // 파일 업로드 버튼 핸들러
  const handleFileUpload = async (field) => {
    try {
      const [fileHandle] = await window.showOpenFilePicker({
        types: [{ description: '이미지 파일', accept: { 'image/*': ['.png', '.jpg', '.jpeg', '.pdf'] } }],
        multiple: false,
      })

      const file = await fileHandle.getFile()
      setFileNames((prev) => ({ ...prev, [field]: file.name }))
      setUploadedFiles((prev) => ({ ...prev, [field]: file }))
      setValue(`${field}_name`, file.name)

      // 업로드 시 URL을 비우고, 업로드 파일을 br_img에 설정
      if (field === 'br_img') {
        setValue('brImgUrl', '') // 기존 URL 초기화
      }
    } catch (error) {
      if (error.name !== 'AbortError') {
        console.error('파일 선택 오류:', error)
      }
    }
  }

  // 사업자등록증 미리보기
  const handlePreview = () => {
    let br_img = getValues('br_img_name')

    if (br_img && !uploadedFiles.br_img) {
      let url = br_img.startsWith('dojang/BR') ? `${s3URL}/${br_img}` : br_img
      window.open(url, '_blank')
    } else if (uploadedFiles.br_img) {
      const url = URL.createObjectURL(uploadedFiles.br_img)
      window.open(url, '_blank')
      URL.revokeObjectURL(url)
    } else {
      alert('미리보기를 할 수 없습니다. 다시 업로드 해주세요.')
    }
  }

  const handlePostCodeSelect = (selectedAddress) => {
    setValue('postNo', selectedAddress.zip_code)
    setValue('addrNo1', selectedAddress.road_address)
    setValue('addrNo2', '')
    setIsModalOpen(false)
  }

  const mutation = useMutation((formData) => createPGState(dojang_id, formData), {
    onSuccess: () => {
      alert('PG 가입 요청이 성공적으로 완료되었습니다.')
      navigate('/home')
    },
    onError: () => alert('PG 가입 요청에 실패했습니다. 다시 시도해주세요.'),
  })

  // PG 가입 제출
  const onSubmit = async (formData) => {
    if (selectedOption === 'agent' && (!uploadedFiles.front_id_card || !uploadedFiles.back_id_card)) {
      alert('필수 서류를 모두 업로드 해주세요.')
      return
    }

    if (businessType === 'corporate' && !formData.coRegNo) {
      setError('coRegNo', { type: 'manual', message: '법인등록번호를 입력해주세요.' })
      return
    }

    const formDataWithFiles = new FormData()
    Object.entries({
      proxy: selectedOption === 'agent' ? '0' : '1',
      coNm: formData.coNm,
      coNo: formData.coNo,
      openDt: formData.openDt,
      repNm: formData.repNm,
      repBirthDt: formData.repBirthDt,
      email: formData.email,
      repHp: formData.repHp,
      telNo: formData.telNo,
      coRegNo: businessType === 'corporate' ? formData.coRegNo : '',
      postNo: formData.postNo,
      addrNo1: formData.addrNo1,
      addrNo2: formData.addrNo2,
    }).forEach(([key, value]) => formDataWithFiles.append(key, value))

    if (selectedOption === 'agent') {
      if (uploadedFiles.br_img) {
        formDataWithFiles.append('br_img', uploadedFiles.br_img)
      } else {
        formDataWithFiles.append('brImgUrl', getValues('br_img_name'))
      }

      Object.keys(uploadedFiles).forEach((key) => {
        if (key !== 'br_img' && uploadedFiles[key]) {
          formDataWithFiles.append(key, uploadedFiles[key])
        }
      })
    }

    mutation.mutate(formDataWithFiles)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className='flex flex-col gap-[20px] justify-center items-center'>
        {/* agent 선택 시 동의 */}
        {selectedOption === 'agent' ? (
          <FlexWrap className='items-start w-1/2'>
            <AlignLabel
              className='mt-2'
              label={<FormattedMessage id='대리계약 동의' />}
              tooltip={[intl.formatMessage({ id: '미동의 시에는 PG 가입을 진행할 수 없습니다' })]}
            />
            <PrimaryButton
              width='156px'
              onClick={() => setAgree(true)}
              minHeight='39px'
              className={` ${
                agree === true ? 'bg-blue-500 text-white font-bold border-[#EEEEEE]' : 'bg-#[EEEEEE] border-[#EEEEEE]'
              }`}
            >
              동의
            </PrimaryButton>
            <PrimaryButton
              width='156px'
              onClick={() => setAgree(false)}
              minHeight='39px'
              className={` ${
                agree === false ? 'bg-blue-500 text-white font-bold border-[#EEEEEE]' : 'bg-#[EEEEEE] border-[#EEEEEE]'
              }`}
            >
              미동의
            </PrimaryButton>
          </FlexWrap>
        ) : null}

        {/* 상호 */}
        <FlexWrap className='items-start w-1/2'>
          <AlignLabel
            className='mt-2'
            label={<FormattedMessage id='상호' />}
            req={true}
          />
          <InputControlled
            name='coNm'
            control={control}
            rules={{
              required: coNmMsg,
              minLength: {
                value: 2,
                message: MinLenMsg,
              },
            }}
            placeholder={coNmMsg}
            disabled={!agree}
          />
        </FlexWrap>

        {/* 사업자번호 */}
        <FlexWrap className='items-start w-1/2'>
          <AlignLabel
            className='mt-2'
            label={<FormattedMessage id='사업자번호' />}
            req={true}
          />
          <InputControlled
            name='coNo'
            control={control}
            rules={{
              required: coNoMsg,
              minLength: {
                value: 2,
                message: MinLenMsg,
              },
            }}
            placeholder={coNoMsg}
            disabled={!agree}
          />
        </FlexWrap>

        {/* 사업자 선택 버튼 */}
        <FlexWrap className='items-start w-1/2'>
          <AlignLabel
            className='mt-2'
            label={<FormattedMessage id='사업자선택' />}
          />
          <PrimaryButton
            width='156px'
            onClick={() => setBusinessType('personal')}
            minHeight='39px'
            className={`${
              businessType === 'personal'
                ? 'bg-blue-500 text-white font-bold border-[#EEEEEE] '
                : 'bg-#[EEEEEE] border-[#EEEEEE]'
            }`}
            disabled={!agree}
          >
            개인사업자
          </PrimaryButton>
          <PrimaryButton
            width='156px'
            onClick={() => setBusinessType('corporate')}
            minHeight='39px'
            className={` ${
              businessType === 'corporate'
                ? 'bg-blue-500 text-white font-bold border-[#EEEEEE]'
                : 'bg-#[EEEEEE] border-[#EEEEEE]'
            }`}
            disabled={!agree}
          >
            법인사업자
          </PrimaryButton>
        </FlexWrap>

        {/* 법인등록번호 */}
        {businessType === 'corporate' && (
          <FlexWrap className='items-start w-1/2'>
            <AlignLabel
              className='mt-2'
              label={<FormattedMessage id='법인등록번호' />}
            />
            <InputControlled
              name='coRegNo'
              control={control}
              rules={{
                minLength: {
                  value: 13,
                  message: coReqMin,
                },
                maxLength: {
                  value: 13,
                  message: coReqMax,
                },
              }}
              placeholder={coRegMsg}
              disabled={!agree}
            />
          </FlexWrap>
        )}

        {/* 사업개시일 */}
        <FlexWrap className='items-start w-1/2'>
          <AlignLabel
            className='mt-2'
            label={<FormattedMessage id='사업개시일' />}
            req={true}
          />
          <InputControlled
            name='openDt'
            control={control}
            rules={{
              pattern: {
                value: /^\d{8}$/,
                message: openMsg,
              },
              required: openMsg,
            }}
            placeholder={openMsg}
            disabled={!agree}
          />
        </FlexWrap>

        {/* 대표자명 */}
        <FlexWrap className='items-start w-1/2'>
          <AlignLabel
            className='mt-2'
            label={<FormattedMessage id='대표자명' />}
            req={true}
          />
          <InputControlled
            name='repNm'
            control={control}
            rules={{
              required: repNmMsg,
              minLength: {
                value: 2,
                message: MinLenMsg,
              },
            }}
            placeholder={repNmMsg}
            disabled={!agree}
          />
        </FlexWrap>

        {/* 대표자 생년월일 */}
        <FlexWrap className='items-start w-1/2'>
          <AlignLabel
            className='mt-2'
            label={<FormattedMessage id='대표자 생년월일' />}
            req={true}
          />
          <InputControlled
            name='repBirthDt'
            control={control}
            rules={{
              required: repBirthDtMsg,
              pattern: {
                value: /^\d{8}$/,
                message: repBirthDtMsg,
              },
            }}
            placeholder={repBirthDtMsg}
            disabled={!agree}
          />
        </FlexWrap>

        {/* 대표자 HP */}
        <FlexWrap className='items-start w-1/2'>
          <AlignLabel
            className='mt-2'
            label={<FormattedMessage id='대표자 HP' />}
            req={true}
          />
          <InputControlled
            name='repHp'
            control={control}
            rules={{
              required: phoneMsg,
              pattern: {
                value: /^01[0|1|6|7|8|9]\d{7,8}$/,
                message: phonePattern,
              },
              minLength: {
                value: 10,
                message: phoneMin,
              },
              maxLength: {
                value: 11,
                message: phoneMax,
              },
            }}
            placeholder={phoneMsg}
            disabled={!agree}
          />
        </FlexWrap>

        {/* 전화번호 */}
        <FlexWrap className='items-start w-1/2'>
          <AlignLabel
            className='mt-2'
            label={<FormattedMessage id='전화번호' />}
          />
          <InputControlled
            name='telNo'
            control={control}
            placeholder={telNoMsg}
            disabled={!agree}
          />
        </FlexWrap>

        {/* 이메일 */}
        <FlexWrap className='items-start w-1/2'>
          <AlignLabel
            className='mt-2'
            label={<FormattedMessage id='이메일' />}
            req={true}
          />
          <InputControlled
            name='email'
            control={control}
            rules={{
              required: emailMsg,
              pattern: {
                value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                message: emailPattern,
              },
            }}
            placeholder={emailMsg}
            disabled={!agree}
          />
        </FlexWrap>

        {/* 우편번호 */}
        <FlexWrap className='items-start w-1/2'>
          <AlignLabel
            className='mt-2'
            label={<FormattedMessage id='우편번호' />}
            req={true}
          />
          <InputControlled
            name='postNo'
            control={control}
            onClick={() => setIsModalOpen(true)}
            rules={{
              required: postMsg,
            }}
            value={getValues('postNo')}
            placeholder={postClickMsg}
            disabled={!agree}
          />
        </FlexWrap>

        {/* 주소 */}
        <FlexWrap className='items-start w-1/2'>
          <AlignLabel
            className='mt-2'
            label={<FormattedMessage id='주소' />}
            req={true}
          />
          <InputControlled
            className='pr-[20px]'
            name='addrNo1'
            control={control}
            rules={{
              required: addrMsg,
            }}
            value={getValues('addrNo1')}
            placeholder={addrMsg}
            disabled={!agree}
          />
          <InputControlled
            name='addrNo2'
            control={control}
            rules={{
              required: addrDetailMsg,
              minLength: {
                value: 2,
                message: MinLenMsg,
              },
            }}
            placeholder={addrDetailMsg}
            disabled={!agree}
          />
        </FlexWrap>

        {/* agent 상태시에만 - 필요서류 (사업자등록증, 신분증(앞,뒤), 통장사본) */}
        {selectedOption === 'agent' ? (
          <>
            <FlexWrap className='items-start w-1/2'>
              <AlignLabel
                className='mt-2'
                label={<FormattedMessage id='사업자등록증' />}
                req={true}
              />
              <div className='flex w-full gap-[20px]'>
                <InputControlled
                  name='br_img_name'
                  control={control}
                  rules={{
                    required: brMsg,
                  }}
                  placeholder={brMsg}
                  disabled={true}
                />
                <IconButton
                  type='button'
                  onClick={handlePreview}
                >
                  <ImageSearchIcon
                    fontSize='30px'
                    className='text-[30px]'
                  />
                </IconButton>
                <PrimaryButton
                  width='156px'
                  minHeight='39px'
                  className=' border-[#EEEEEE]'
                  onClick={() => handleFileUpload('br_img')}
                  disabled={!agree}
                >
                  업로드
                </PrimaryButton>
              </div>
            </FlexWrap>
            {/* 신분증 */}
            <FlexWrap className='items-start w-1/2'>
              <AlignLabel
                className='mt-2'
                label={<FormattedMessage id='신분증 (앞면)' />}
                req={true}
              />
              <div className='flex flex-col w-full gap-[20px]'>
                <div className='flex gap-[20px]'>
                  <InputControlled
                    name='front_id_card_name'
                    control={control}
                    rules={{
                      required: frontIdMsg,
                    }}
                    placeholder={frontIdMsg}
                    disabled={true}
                  />
                  <PrimaryButton
                    width='156px'
                    minHeight='39px'
                    className=' border-[#EEEEEE]'
                    disabled={!agree}
                    onClick={() => handleFileUpload('front_id_card')}
                  >
                    업로드
                  </PrimaryButton>
                </div>
              </div>
            </FlexWrap>
            <FlexWrap className='items-start w-1/2'>
              <AlignLabel
                className='mt-2'
                label={<FormattedMessage id='신분증 (뒷면)' />}
                req={true}
              />
              <div className='flex flex-col w-full gap-[20px]'>
                <div className='flex gap-[20px]'>
                  <InputControlled
                    name='back_id_card_name'
                    control={control}
                    rules={{
                      required: backIdMsg,
                    }}
                    placeholder={frontIdMsg}
                    disabled={true}
                  />
                  <PrimaryButton
                    width='156px'
                    minHeight='39px'
                    className=' border-[#EEEEEE]'
                    disabled={!agree}
                    onClick={() => handleFileUpload('back_id_card')}
                  >
                    업로드
                  </PrimaryButton>
                </div>
              </div>
            </FlexWrap>

            {/* 통장사본 */}
            <FlexWrap className='items-start w-1/2'>
              <AlignLabel
                label={<FormattedMessage id='통장사본' />}
                req={true}
              />
              <div className='flex w-full gap-[20px]'>
                <InputControlled
                  name='account_copy_name'
                  control={control}
                  placeholder={accountMsg}
                  rules={{
                    required: accountMsg,
                  }}
                  value={fileNames.account_copy}
                  disabled={true}
                />
                <PrimaryButton
                  width='156px'
                  minHeight='39px'
                  className='border-[#EEEEEE]'
                  onClick={() => handleFileUpload('account_copy')}
                >
                  업로드
                </PrimaryButton>
              </div>
            </FlexWrap>
          </>
        ) : null}

        {/* 우편번호 등록 모달 */}
        <ModalAddress
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          control={true}
          setValue={handlePostCodeSelect}
        />

        <FlexWrap className='pt-[20px]'>
          <PgSignButton disabled={!agree} />
        </FlexWrap>
      </div>
    </form>
  )
}

export default PgSignForm
