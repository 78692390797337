import React from 'react'
import ContainerNoPadding from '../../../LAYOUT/CONTAINER/ContainerNoPadding'
import Typography from '../../../ATOM/TEXT/Typography'
import { intlTransLang } from '../../../../UTILS/render/TRANSLATION/transLang'
import { FormattedMessage, useIntl } from 'react-intl'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import PrimaryButton from '../../../ATOM/BUTTON/PrimaryButton'
import MainImage from '../../../../asset/Images/Image/Background/healthcare_main.png'
import ButtonWrap from '../../../LAYOUT/WRAP/ButtonWrap'
import FlexWrap from '../../../LAYOUT/WRAP/FlexWrap'
import FlexColWrap from '../../../LAYOUT/WRAP/FlexColWrap'
import Logo from '../../../../asset/Images/Logo/HealthCare/healthcare_logo.png'
import People from '../../../../asset/Images/Image/HealthCare/Model/healthcare_people.png'
import ResultImage from '../../../../asset/Images/Image/HealthCare/Model/healthcare_result.png'
import Model from '../../../../asset/Images/Image/HealthCare/Model/BC380_PLUS_two.png'
import SecondaryLineTitle from '../../../MOLECULES/TEXT/LINETITLE/SecondaryLineTitle'
import appImg1 from '../../../../asset/Images/Image/HealthCare/App/HealthCare_App_image_1.png'
import appImg2 from '../../../../asset/Images/Image/HealthCare/App/HealthCare_App_image_2.png'
import appImg3 from '../../../../asset/Images/Image/HealthCare/App/HealthCare_App_image_3.png'
import appImg4 from '../../../../asset/Images/Image/HealthCare/App/HealthCare_App_image_4.png'
import ModelSide from '../../../../asset/Images/Image/HealthCare/Model/healthcare_model.png'
import AllAtOnceIcon from '../../../../asset/Images/Image/HealthCare/Launching/all_at_once_kr.png'
import TwoYearsIcon from '../../../../asset/Images/Image/HealthCare/Launching/2years_ko_icon.png'
import ThreeYearsIcon from '../../../../asset/Images/Image/HealthCare/Launching/3years_ko_icon.png'
import AllAtOnceENIcon from '../../../../asset/Images/Image/HealthCare/Launching/all_at_once_en.png'
import TwoYearsENIcon from '../../../../asset/Images/Image/HealthCare/Launching/2years_en_icon.png'
import ThreeYearsIconENIcon from '../../../../asset/Images/Image/HealthCare/Launching/3years_en_icon.png'
import ColorBox from '../../../MOLECULES/BOX/COLORBOX/ColorBox'

const s3URL = process.env.REACT_APP_AWS_S3_FILE_URL

const text = [
  {
    content: '측정 방법 : 8개 접촉전극을 이용한 4전극법',
  },
  { content: '신장 측정 : 초음파 자동 감지' },
  { content: '측정 항목 : 3가지 주파수(5kHz, 50kHz, 250kHz) 전신 및 5가지 부위(왼팔, 오른팔, 왼다리, 오른다리, 몸통)' },
  { content: '측정 체중 : 최대 250 kg' },
  { content: '무게 : 본체- 약 18.0 kg, 신장계 장착- 약 21.3 kg' },
  {
    content: '입력 장치 : 7인치 터치스크린, 키패드',
  },
  { content: '전송 매체 : USB 2.0 단자, RS-232C 직렬 포트, 블루투스 어댑터' },
  { content: '사용 목적 : 임피던스방식에 의해 인체에 에너지를 주어 체지방을 산출하는 기구' },
  {
    content:
      '기본 구성품 : 본체, USB 메모리 (사용설명서 및 데이터관리 프로그램), 전원케이블 및 어댑터, USB 케이블, M6 접시머리볼트, 4mm 육각렌치, 바디힌지커버',
  },
  {
    content: '앱 연동 : 태권월드 앱',
  },
]

const HealthCareIntro = () => {
  const intl = useIntl()

  const locale = localStorage.getItem('locale')
  // image change
  const AllAtOnceSrcIcon = locale !== 'ko' ? AllAtOnceENIcon : AllAtOnceIcon
  const TwoYearsSrcIcon = locale !== 'ko' ? TwoYearsENIcon : TwoYearsIcon
  const ThreeYearsSrcIcon = locale !== 'ko' ? ThreeYearsIconENIcon : ThreeYearsIcon

  const handleDownload = async (type) => {
    try {
      const response = await fetch(`${s3URL}/document/ACCUNIQ_BC380_PLUS_${type}_${locale?.toUpperCase()}.pdf`)

      if (!response.ok) {
        throw new Error('파일 다운로드에 실패했습니다.')
      }

      const blob = await response.blob() // Blob 객체 생성
      const url = window.URL.createObjectURL(blob) // Blob URL 생성

      const a = document.createElement('a') // 링크 생성
      a.href = url
      a.download = `ACCUNIQ_BC380_PLUS_${type}.pdf` // 다운로드할 파일 이름 설정
      document.body.appendChild(a) // 링크를 DOM에 추가
      a.click() // 클릭 이벤트 발생
      document.body.removeChild(a) // 링크 제거
      window.URL.revokeObjectURL(url) // 메모리 해제
    } catch (error) {
      console.error('다운로드 오류:', error)
    }
  }

  return (
    <ContainerNoPadding>
      <FlexColWrap
        gap='0'
        className='bg-[#fff]'
      >
        <img
          src={MainImage}
          alt='ACCUNIQ'
          className={'w-full'}
        />

        {/* IntroSection */}
        <FlexWrap className={containerStyle}>
          <FlexColWrap className={'gap-[36px] w-[599px] items-center'}>
            <img
              src={Logo}
              alt='ACCUNIQ'
              className={'w-[310px] object-cover'}
            />
            <Typography
              variant='h4'
              leading='42px'
              className={'w-[600px] break-keep pl-[80px]'}
            >
              <FormattedMessage
                id='부위별 체성분 측정이 가능한 체성분 분석기'
                values={{ br: (v) => <br /> }}
              />
            </Typography>
            <img
              src={People}
              alt=''
              className={'w-[600px] object-cover'}
            />
          </FlexColWrap>
          <img
            src={ResultImage}
            alt=''
          />
        </FlexWrap>

        {/* ModelSection */}
        <FlexWrap className={containerStyle}>
          <div className={'w-[510px] h-[710px] flex items-center relative'}>
            <img
              src={Model}
              alt='K-VX1'
              className={'m-auto z-[2]'}
            />
          </div>
          <FlexColWrap className={'w-[640px]'}>
            <SecondaryLineTitle>
              <FormattedMessage id='체성분 분석기 (모델명:BC380 PLUS)' />
            </SecondaryLineTitle>
            <FlexColWrap className={'px-[10px] w-[575px] break-keep'}>
              {React.Children.toArray(
                text?.map((item) => (
                  <Typography
                    variant='h5'
                    leading='34px'
                    className='break-keep'
                  >
                    <FormattedMessage
                      id={item.content}
                      values={{
                        span: (v) => <span className='font-bold'>{v}</span>,
                      }}
                    />
                  </Typography>
                )),
              )}
            </FlexColWrap>
          </FlexColWrap>
        </FlexWrap>

        {/* AppSection */}
        <div className={'flex justify-center pt-[150px]'}>
          <Typography className={`text-[36px] text-[#191919] font-bold text-center`}>
            <FormattedMessage id='태권월드 앱과의 연동으로 간편하게 헬스데이터 조회' />
          </Typography>
        </div>
        <div className={'mt-[50px] grid grid-cols-6 px-[135px]'}>
          <img
            className={'w-[240px]'}
            src={`${s3URL}/selvas/hc_mobile_1.jpg`}
            alt=''
          />
          <img
            className={'w-[240px]'}
            src={`${s3URL}/selvas/hc_mobile_2.jpg`}
            alt=''
          />
          <img
            className={'w-[240px]'}
            src={`${s3URL}/selvas/hc_mobile_3.jpg`}
            alt=''
          />
          <img
            className={'w-[240px]'}
            src={`${s3URL}/selvas/hc_mobile_4.jpg`}
            alt=''
          />
          <img
            className={'w-[240px]'}
            src={`${s3URL}/selvas/hc_mobile_5.jpg`}
            alt=''
          />
          <img
            className={'w-[240px]'}
            src={`${s3URL}/selvas/hc_mobile_6.jpg`}
            alt=''
          />
        </div>

        {/* DashboardSection */}
        <div className={'flex justify-center pt-[150px]'}>
          <Typography className={`text-[36px] text-[#191919] font-bold text-center`}>
            <FormattedMessage id='헬스케어 전용 대시보드 제공' />
          </Typography>
        </div>
        <div className={'mt-[50px] px-[135px]'}>
          <img
            className={'w-full'}
            src={`${s3URL}/selvas/hc_dashboard.jpg`}
            alt=''
          />
        </div>

        {/* ProgramSection */}
        <FlexWrap className={containerStyle}>
          <img
            src={ModelSide}
            alt='BC380'
            // className={'max-h-[500px]'}
          />
          <FlexColWrap className={'w-[650px] gap-[40px]'}>
            <FlexColWrap>
              <SecondaryLineTitle>
                <FormattedMessage id='ACCUNIQ 판매 가격' />
              </SecondaryLineTitle>
              <FlexColWrap className={'px-[10px] gap-[30px] break-keep'}>
                <FlexColWrap gap='0'>
                  <Typography
                    variant='h5'
                    leading='34px'
                  >
                    <FormattedMessage
                      id='구매 제품명 : 체성분 분석기 (모델명 : BC380 PLUS)'
                      values={{
                        span: (v) => <span className='font-bold'>{v}</span>,
                        br: (v) => <br />,
                      }}
                    />
                  </Typography>
                  <Typography
                    variant='h5'
                    leading='34px'
                  >
                    <FormattedMessage
                      id='앱 연동 : 태권월드 [구글플레이/앱스토어]'
                      values={{ span: (v) => <span className='font-bold'>{v}</span> }}
                    />
                  </Typography>
                </FlexColWrap>
                <div className={'w-[628px] relative'}>
                  <img
                    src={AllAtOnceSrcIcon}
                    className={'absolute left-[-1px] top-[-11px] w-[85px]'}
                    alt=''
                  />
                  <ColorBox className={'pl-[150px]'}>
                    <Typography
                      leading='38px'
                      className={'text-[#FFFFFF] text-[22px]'}
                    >
                      <FormattedMessage
                        id='판매가격 : 기본 기기 315만원 옵션: 신장계 35만원 + 프린터 12.5만원 (VAT 포함)'
                        values={{
                          span: (v) => <span className='font-bold text-[#FFECA9]'>{v}</span>,
                          br: (v) => <br />,
                        }}
                      />
                    </Typography>
                  </ColorBox>
                </div>
              </FlexColWrap>
            </FlexColWrap>
            <FlexColWrap>
              <SecondaryLineTitle>
                <FormattedMessage id='렌탈 프로그램' />
              </SecondaryLineTitle>
              <FlexColWrap className={'px-[10px] gap-[80px] break-keep'}>
                <FlexColWrap gap='0'>
                  <Typography
                    variant='h5'
                    leading='34px'
                  >
                    <FormattedMessage
                      id='소유권 이전 : 약정 기간까지 사용하시면 소유권 이전'
                      values={{ span: (v) => <span className='font-bold'>{v}</span> }}
                    />
                  </Typography>
                  <Typography
                    variant='h5'
                    leading='34px'
                  >
                    <FormattedMessage
                      id='A/S : 무상 1년'
                      values={{ span: (v) => <span className='font-bold'>{v}</span> }}
                    />
                  </Typography>
                </FlexColWrap>
                <FlexWrap className={'items-center justify-between'}>
                  <div className={'w-[290px] relative'}>
                    <img
                      src={TwoYearsSrcIcon}
                      className={'absolute left-0 top-[-70px]'}
                      alt=''
                    />
                    <ColorBox>
                      <Typography
                        leading='38px'
                        className={'text-[#FFFFFF] text-[18px] w-[217px] m-auto break-keep'}
                      >
                        <FormattedMessage
                          id='1. 보증금 : 1,000,000원 2. 월렌탈료: 150,000원'
                          values={{
                            span: (v) => <span className='font-bold text-[#FFECA9]'>{v}</span>,
                            br: (v) => <br />,
                          }}
                        />
                      </Typography>
                      <FlexWrap className={'ml-[31.5px] gap-[5px]'}>
                        <Typography
                          leading='38px'
                          className={'text-[#FFFFFF] text-[18px]'}
                        >
                          <FormattedMessage
                            id='3. 잔금 : 만기 시 소유권 이전'
                            values={{
                              span: (v) => <span className='font-bold text-[#FFECA9]'>{v}</span>,
                              br: (v) => <br />,
                            }}
                          />
                        </Typography>
                      </FlexWrap>
                    </ColorBox>
                  </div>
                  <div className={'w-[290px] relative'}>
                    <img
                      src={ThreeYearsSrcIcon}
                      className={'absolute left-0 top-[-70px]'}
                      alt=''
                    />
                    <ColorBox>
                      <Typography
                        leading='38px'
                        className={'text-[#FFFFFF] text-[18px] w-[217px] m-auto break-keep'}
                      >
                        <FormattedMessage
                          id='1. 보증금 : 1,000,000원 2. 월렌탈료: 120,000원'
                          values={{
                            span: (v) => <span className='font-bold text-[#FFECA9]'>{v}</span>,
                            br: (v) => <br />,
                          }}
                        />
                      </Typography>
                      <FlexWrap className={'ml-[31.5px] gap-[5px]'}>
                        <Typography
                          leading='38px'
                          className={'text-[#FFFFFF] text-[18px]'}
                        >
                          <FormattedMessage
                            id='3. 잔금 : 만기 시 소유권 이전'
                            values={{
                              span: (v) => <span className='font-bold text-[#FFECA9]'>{v}</span>,
                              br: (v) => <br />,
                            }}
                          />
                        </Typography>
                      </FlexWrap>
                    </ColorBox>
                  </div>
                </FlexWrap>
              </FlexColWrap>
            </FlexColWrap>
            <div>
              <Typography>
                · <FormattedMessage id='렌탈프로그램 이용시 보증보험 가입이 필요하며 별도의 보험료가 발생합니다.' />
              </Typography>
              <Typography>
                · <FormattedMessage id='대한민국 지역 기준이며, 해외는 적용되지 않습니다.' />
              </Typography>
              <Typography>
                · <FormattedMessage id='해외는 별도 문의하시면 됩니다.' />
              </Typography>
            </div>
          </FlexColWrap>
        </FlexWrap>

        {/* download button */}
        <div className={'pt-[150px] flex items-center justify-center gap-[15px]'}>
          <PrimaryButton
            className='flex items-center'
            onClick={() => handleDownload('CATALOG')}
          >
            {intlTransLang(intl, '카탈로그')}
            <FileDownloadOutlinedIcon className='ml-1' />
          </PrimaryButton>
          <PrimaryButton
            className='flex items-center'
            onClick={() => handleDownload('MANUAL')}
          >
            {intlTransLang(intl, '사용설명서')}
            <FileDownloadOutlinedIcon className='ml-1' />
          </PrimaryButton>
        </div>
        <Typography className={'self-center text-3xl font-bold py-[120px]'}>
          {intlTransLang(intl, '구매 문의', { num: '010-7615-6646' })}
        </Typography>
      </FlexColWrap>
    </ContainerNoPadding>
  )
}

export default HealthCareIntro

const containerStyle = 'items-center justify-center gap-[250px] pt-[150px]'
