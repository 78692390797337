import React from 'react'
import ReplayIcon from '@mui/icons-material/Replay'
import Typography from '../../../ATOM/TEXT/Typography'
import { IconButton } from '@mui/material'
import { FormattedMessage } from 'react-intl'

const ApiErrorFallbackSelectBox = ({ error, resetErrorBoundary }) => {
  console.log(error)
  return (
    <>
      <div
        className='flex items-center justify-between'
        style={{
          backgroundColor: '#fff',
          borderRadius: '5px',
          paddingLeft: '15px',
          paddingRight: '5px',
          textAlign: 'left',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          border: '1px solid #b7b7b7',
          width: '200px',
          height: '48px',
        }}
      >
        <Typography
          variant='h6'
          className='text-[#e5adad]'
        >
          <u>
            <FormattedMessage id='불러오기 실패' />
          </u>
        </Typography>
        <IconButton
          width='46px'
          height='46px'
          onClick={() => resetErrorBoundary()}
        >
          <ReplayIcon />
        </IconButton>
      </div>
    </>
  )
}

export default ApiErrorFallbackSelectBox
