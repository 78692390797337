import React, { useState } from 'react'
import Container from '../../../../UI/LAYOUT/CONTAINER/Container'
import { intlTransLang } from '../../../../UTILS/render/TRANSLATION/transLang'
import { useIntl } from 'react-intl'
import ApiErrorBoundary from '../../../../UI/TEMPLATES/ERROR/ErrorBoundary/ApiErrorBoundary'
import FlexWrap from '../../../../UI/LAYOUT/WRAP/FlexWrap'
import PgSignForm from './Components/PgSignForm'
import SelectTypePg from './Components/SelectTypePg'

const PgSignComponent = () => {
  const intl = useIntl()
  const [actType, setActType] = useState(null) // 선택한 옵션 상태 (agent 또는 direct)
  const [showForm, setShowForm] = useState(false) // Form 표시 여부

  // Form으로 이동하는 함수
  const handleNext = (selectedOption) => {
    setActType(selectedOption) // 선택한 옵션 상태 설정
    setShowForm(true) // Form을 표시하도록 설정
  }

  return (
    <>
      <Container title={<FlexWrap className='items-center gap-[15px]'>{intlTransLang(intl, 'PG 가입요청')}</FlexWrap>}>
        <ApiErrorBoundary>
          {showForm ? <PgSignForm selectedOption={actType} /> : <SelectTypePg onNext={handleNext} />}
        </ApiErrorBoundary>
      </Container>
    </>
  )
}

export default PgSignComponent
