import authApi from '../authApi'

// 반 리스트 조회(수정)
export const getClassList = async (body) => {
  const res = await authApi.post('class/classreadforid', body)
  return res.data
}

// 반 조회 (수정 get -> post)
export const getClassInfo = async (body) => {
  const res = await authApi.post('class/classread', body)
  // return res.data[0]
  return res.data.class_info[0]
}

// 요일별 반 정보 조회 (수정)
export const getClassInfoList = async (body) => {
  const res = await authApi.post('class/classreadbyday', body)
  return res.data
  // return res.data
}

// 반 삭제 (수정 delete -> post)
export const deleteClass = async (body) => {
  const res = await authApi.post('class/classdelete', body)
  return res.data
}

// 반 수정 (수정)
export const updateClass = async (body) => {
  const res = await authApi.post('class/classupdate', body)
  return res.data
}

export const getClassTeacher = async (classId, body) => {
  const res = await authApi.post(`class/classupdate/${classId}`, body)
  return res
}

// 반 담당하지 않는 사범 조회(수정 get -> post)
export const getTeacherForAdd = async (body) => {
  const res = await authApi.post('kwanjang/instructorreadexcept', body)
  return res.data
}

// 반 담당하는 사범 조회 (수정 get -> post)
export const getTeacherForDel = async (body) => {
  const res = await authApi.post('kwanjang/instructorread', body)
  return res.data
}

// 반 담당 사범 추가 (수정 get -> post)
export const addTeacherToClass = async (body) => {
  const res = await authApi.post('kwanjang/instructorcreatebytitle', body)
  // /${dojangId}/${classId}/${instructorType}/${instructorId}`)
  return res.data
}

// 반 담당 사범 삭제 (수정 get -> post)
export const delTeacherToClass = async (body) => {
  const res = await authApi.post('kwanjang/instructordeletebytitle', body)
  return res.data
}

// 반 생성 (수정)
export const createClass = async (body) => {
  const res = await authApi.post('class/classcreate', body)
  return res.data
}

// 반 소속되지 않은 수련생 조회(수정 get -> post)
export const getStudentForAdd = async (body) => {
  const res = await authApi.post('student/studentreadexcept', body)
  return res.data
}

// 반에 소속생 추가 (수정 get -> post)
export const addStudentToClass = async (body) => {
  const res = await authApi.post('student/transferclass', body)
  return res.data
}

// 반 소속된 수련생 조회 (수정 get -> post)
export const getStudentForDel = async (body) => {
  const res = await authApi.post('student/studentreadbyclass', body)
  return res.data
}

// 반 소속생 삭제 (수정 get -> post)
export const delStudentToClass = async (body) => {
  const res = await authApi.post('student/studentdeletefromclass', body)
  return res.data
}

// 기존 수련생 등록 (생성)
export const connectStudent = async (body) => {
  const res = await authApi.post('student/studentclassconnect', body)
  return res.data
}
