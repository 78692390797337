import React, { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { useQuery } from '@tanstack/react-query'
import { getBulkListExcel } from '../../../../../../api/Student/studentApi'
import FlexColWrap from '../../../../../../UI/LAYOUT/WRAP/FlexColWrap'
import FlexWrap from '../../../../../../UI/LAYOUT/WRAP/FlexWrap'
import BulkEditHeader from './BulkEditHeader'
import dojangIdAtom from '../../../../../../recoil/atom/dojangIdAtom'
import BulkEditTable from './BulkEditTable'

const BulkEditComponents = ({ setUpdatedData }) => {
  const dojang_id = useRecoilValue(dojangIdAtom)

  const { data } = useQuery(['getBulkListExcel', dojang_id], () => getBulkListExcel(dojang_id), {
    enabled: !!dojang_id,
  })

  const [localData, setLocalData] = useState(data || []) // 로컬 데이터 상태
  const [selectedStudents, setSelectedStudents] = useState([]) // 선택된 학생 인덱스 상태

  // 데이터 로드 후 localData 초기화
  useEffect(() => {
    if (data) {
      setLocalData(data)
    }
  }, [data])

  // localData가 변경될 때마다 상위 컴포넌트로 전달
  useEffect(() => {
    setUpdatedData(localData)
  }, [localData, setUpdatedData])

  // 학년 업데이트(증,감) 함수
  const updateGrade = (operation) => {
    setLocalData((prevData) => {
      return prevData.map((student, index) => {
        if (selectedStudents.includes(index)) {
          const currentGrade = parseInt(student.SCHOOL_GRADE)

          // 증가와 감소에 대한 조건 추가
          if (operation === 'increase' && currentGrade < 13) {
            // 현재 학년이 13 미만일 때만 증가
            const newGrade = currentGrade + 1
            return { ...student, SCHOOL_GRADE: newGrade.toString() } // 문자열로 변환하여 저장
          } else if (operation === 'decrease' && currentGrade > 0) {
            // 현재 학년이 0 초과일 때만 감소
            const newGrade = currentGrade - 1
            return { ...student, SCHOOL_GRADE: newGrade.toString() } // 문자열로 변환하여 저장
          }
        }
        return student
      })
    })
  }

  // 학생 레벨 업데이트 함수
  const updateLevel = (isPromotion = false) => {
    setLocalData((prevData) => {
      return prevData.map((student, index) => {
        if (selectedStudents.includes(index)) {
          let currentLevel = student.STUDENT_LEVEL // 현재 학생 레벨

          // 학생 레벨 업데이트
          if (isPromotion) {
            // 승급일 경우
            if (currentLevel === '4품') {
              currentLevel = '1단' // 4품에서 1단으로 전환
            } else if (currentLevel === '1급') {
              currentLevel = '1품' // 1급에서 1품으로 전환
            } else if (currentLevel.includes('급')) {
              const levelNumber = parseInt(currentLevel) // "8급"에서 숫자 추출
              if (!isNaN(levelNumber)) {
                currentLevel = (levelNumber - 1).toString() + '급' // 1 증가
              }
            } else if (currentLevel.includes('품')) {
              const levelNumber = parseInt(currentLevel[0]) // "1품"에서 숫자 추출
              if (!isNaN(levelNumber) && levelNumber < 10) {
                currentLevel = (levelNumber + 1).toString() + '품' // 1 증가
              }
            } else if (currentLevel.includes('단')) {
              const levelNumber = parseInt(currentLevel[0]) // "9단"에서 숫자 추출
              if (!isNaN(levelNumber) && levelNumber < 10) {
                currentLevel = (levelNumber + 1).toString() + '단' // 1 증가
              }
            }
          } else {
            // 감급일 경우
            if (currentLevel === '1단') {
              currentLevel = '4품' // 1단에서 4품으로 전환
            } else if (currentLevel === '1품') {
              currentLevel = '1급' // 1품에서 1급으로 전환
            } else if (currentLevel.includes('급')) {
              const levelNumber = parseInt(currentLevel) // "8급"에서 숫자 추출
              if (!isNaN(levelNumber) && levelNumber < 10) {
                currentLevel = (levelNumber + 1).toString() + '급' // 1 감소
              }
            } else if (currentLevel.includes('품')) {
              const levelNumber = parseInt(currentLevel[0]) // "4품"에서 숫자 추출
              if (!isNaN(levelNumber) && levelNumber > 1) {
                currentLevel = (levelNumber - 1).toString() + '품' // 1 감소
              }
            } else if (currentLevel.includes('단')) {
              const levelNumber = parseInt(currentLevel[0]) // "9단"에서 숫자 추출
              if (!isNaN(levelNumber) && levelNumber > 1) {
                currentLevel = (levelNumber - 1).toString() + '단' // 1 감소
              }
            }
          }

          return { ...student, STUDENT_LEVEL: currentLevel } // 업데이트된 레벨 저장
        }
        return student // 변경하지 않을 학생은 그대로 반환
      })
    })
  }

  // 일괄 승급
  const promoteStudents = () => {
    updateLevel(true)
  }

  // 일괄 감급
  const decreaseLevel = () => {
    updateLevel(false)
  }

  return (
    <FlexColWrap>
      <FlexWrap>
        <BulkEditHeader
          data={localData} // BulkButtonList에서 사용하는 data 전달
          onPromoteStudents={promoteStudents}
          onUpdateGrade={updateGrade}
          onDecreaseLevel={decreaseLevel}
          selectedStudents={selectedStudents}
          setUpdatedData={setLocalData} // setUpdatedData 함수 전달
        />
      </FlexWrap>
      <div className='max-h-[650px] w-[1630px] overflow-x-scroll overflow-y-scroll scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-100'>
        <table className='border-collapse border border-solid border-[#D0D6DB] text-[10px] bg-white'>
          <BulkEditTable
            data={localData}
            selectedStudents={selectedStudents}
            setSelectedStudents={setSelectedStudents}
          />
        </table>
      </div>
    </FlexColWrap>
  )
}

export default BulkEditComponents
